var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"video-bg-warpper",attrs:{"color":"rgab(0,0,0,.7)","min-height":"800","dark":""}},[_c('CarousalHome'),_c('div',{staticClass:"mask"}),_c('v-container',{staticClass:"main-container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('div',[_c('h1',{staticClass:"\n                text-xl-h2 text-lg-h3 text-md-h4 text-h5\n                font-weight-medium\n              ",staticStyle:{"line-height":"150%"}},[_vm._v(" "+_vm._s(_vm.$t("home.title1"))+" ")])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('div',[_c('h1',{staticClass:"\n                text-xl-h2 text-lg-h3 text-md-h4 text-h5\n                font-weight-medium\n              ",staticStyle:{"line-height":"150%"}},[_vm._v(" "+_vm._s(_vm.$t("home.title2"))+" ")]),_c('p',{staticClass:"text-md-body-1 text-body-2 mt-8"},[_vm._v(" "+_vm._s(_vm.$t("home.subTitle"))+" ")])])])],1)],1)],1),_c('NavGroup'),_c('v-row',{staticClass:"fixed-bg2",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"d-flex flex-column align-center justify-center",attrs:{"height":"400","color":"rgba(255,255,255,1)"}},[_c('h1',{staticClass:"text-h3 font-weight-bold"},[_vm._v("買取対応")]),_c('p',{staticClass:"text-center mt-8"},[_vm._v(_vm._s(_vm.$t("home.buy.subTitle")))])])],1)],1),_c('v-container',{staticClass:"py-4 py-lg-8"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}}),_c('v-card',{staticClass:"mx-auto text-center",attrs:{"max-width":"1200","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{attrs:{"to":{ name: 'ProductPrice', params: { id: '#cooper-metals' } }}},[_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('h3',{staticClass:"card-title py-5"},[_vm._v("銅スクラップ")]),_c('v-img',{attrs:{"src":require("../assets/images/metal/copper_img2.jpg"),"height":"400"}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"rgba(44,55,83,.8)"}},[_c('v-btn',{attrs:{"to":{
                        name: 'ProductPrice',
                        params: { id: '#cooper-metals' },
                      }}},[_vm._v("詳しく")])],1):_vm._e()],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('router-link',{attrs:{"to":{ name: 'ProductPrice', params: { id: '#none-metals' } }}},[_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('h3',{staticClass:"card-title py-5"},[_vm._v("非鉄スクラップ")]),_c('v-img',{attrs:{"src":require("../assets/images/metal/nonMetal17.jpg"),"height":"400"}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"rgba(44,55,83,.8)"}},[_c('v-btn',{attrs:{"to":{
                        name: 'ProductPrice',
                        params: { id: '#none-metals' },
                      }}},[_vm._v("詳しく")])],1):_vm._e()],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('router-link',{attrs:{"to":{ name: 'ProductPrice', params: { id: '#iron-metals' } }}},[_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('h3',{staticClass:"card-title py-5"},[_vm._v("鉄スクラップ")]),_c('v-img',{attrs:{"src":require("../assets/images/nav/iron.png"),"height":"400"}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"rgba(44,55,83,.8)"}},[_c('v-btn',{attrs:{"to":{
                        name: 'ProductPrice',
                        params: { id: '#iron-metals' },
                      }}},[_vm._v("詳しく")])],1):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }